import { type ComputedRef, computed, isProxy, toRaw } from 'vue';
import { useContext } from '~/composables/useContext';
import type { Breadcrumb } from '@shopware-pwa/composables-next';
import type { Schemas } from '#shopware';

export function useBreadcrumbsMainNavigation(
    mainNavigation: ComputedRef<Schemas['Category'][]>,
    newBreadcrumbs?: Breadcrumb[],
) {
    // Store for breadcrumbs
    const _breadcrumbs = useContext<Breadcrumb[]>('swBreadcrumb', {
        replace: newBreadcrumbs,
    });

    /**
     * Clear breadcrumbs store
     */
    const clearBreadcrumbs = () => {
        _breadcrumbs.value = [];
    };

    /**
     * Created breadcrumbs with main-navigation data store
     */
    const createdBreadcrumbs = () => {
        _breadcrumbs.value = [];
    };

    const findMatchingNodes = (breadcrumb: Breadcrumb[], nodes: Schemas['Category'][]): Schemas['Category'][] => {
        const matchingNodes: Schemas['Category'][] = [];
        if (breadcrumb.length === 0 || nodes.length === 0) {
            return matchingNodes;
        }

        const [currentCrumb, ...remainingCrumbs] = breadcrumb;

        for (const node of nodes) {
            if (node.name === currentCrumb.name || !node.name) {
                if (remainingCrumbs.length === 0) {
                    matchingNodes.push(node);
                } else {
                    matchingNodes.push(node);
                    if (!node.children) {
                        continue;
                    }
                    const childMatchingNodes = findMatchingNodes(remainingCrumbs, node.children);
                    if (!childMatchingNodes) {
                        continue;
                    }
                    matchingNodes.push(...childMatchingNodes);
                }
            }
        }

        return matchingNodes;
    };

    const extendBreadcrumbWithSeoPath = (
        breadcrumb: Breadcrumb[],
        matchingNodes: Schemas['Category'][],
    ): Breadcrumb[] => {
        const extendedBreadcrumb: Breadcrumb[] = [...breadcrumb];

        for (let i = 0; i < extendedBreadcrumb.length; i++) {
            const breadcrumbItem = extendedBreadcrumb[i];
            for (const matchingNode of matchingNodes) {
                if (matchingNode.name === breadcrumbItem.name && matchingNode.seoUrls?.[0]?.seoPathInfo) {
                    breadcrumbItem.path = '/' + matchingNode.seoUrls?.[0]?.seoPathInfo;
                    break;
                }
            }
        }

        return extendedBreadcrumb;
    };

    let breadcrumbRaw = null;
    if (isProxy(_breadcrumbs.value)) {
        breadcrumbRaw = toRaw(_breadcrumbs.value);
    }

    let navigationRaw = null;
    if (isProxy(mainNavigation.value)) {
        navigationRaw = toRaw(mainNavigation.value) as Schemas['Category'][];
    }

    if (breadcrumbRaw && navigationRaw) {
        const matchingNodes = findMatchingNodes(breadcrumbRaw, navigationRaw);

        if (matchingNodes.length > 0) {
            createdBreadcrumbs.value = extendBreadcrumbWithSeoPath(breadcrumbRaw, matchingNodes);
        }
    }

    return {
        clearBreadcrumbs,
        findMatchingNodes,
        extendBreadcrumbWithSeoPath,
        breadcrumbs: computed(() => createdBreadcrumbs.value),
    };
}
